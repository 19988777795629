<!-- Agile planning result for uniliver -->
<template>
  <div class="w-100 mt-5">
    <b-container fluid>
      <div style="margin-top: 84px" :class="breakDown ? 'card' : ''"></div>
      <b-row>
        <div class="col-md-12 col-lg-12 col-sm-12 my-4">
          <div class="card pb-5 pb-lg-0 h-100">
            <div
              class="d-flex mx-3 mt-3"
              style="justify-content: space-between; align-items: center"
            >
              <h5 class="card-heading">Month on Month Trends by Channel</h5>
              <div class="">
                <w-single-dropdown
                  :options="adtypeList"
                  :value="selectedChannelWiseCost"
                  @selectedOptions="getSelectedChannelWiseCost($event)"
                />
              </div>
            </div>
            <line-chart
              yAxisTitle="Millions"
              :boxshadow="true"
              :chartData="channelCostChartData"
              ref="digitalMediaLineChart"
            />
          </div>
        </div>
        <!-- Campaign Type -->
        <!--<div class="col-12 d-none">
          <div class="card mt-5 p-5">
            <StripChart title="Media Mix" :chartData="mediaMixData" />
          </div>
        </div>-->
        <!-- Social Bar Graph -->
        <div class="col-6">
          <div class="card mt-5 p-4">
            <BarChart :chartData="socialBarData" />
          </div>
        </div>
        <!-- New Stores 
        <div class="col-12 col-lg-6 mt-5">
          <div class="card pb-5 pb-lg-0 h-100">
            <div class="p-4">
              <ColumnChart :chartData="newStoreData" />
            </div>
          </div>
        </div>-->
        <!-- Sales - Region wise -->
        <div class="col-12 col-lg-6 mt-5">
          <div class="card pb-5 pb-lg-0 h-100">
            <div class="p-4">
              <ColumnChart :chartData="stackChartData" />
            </div>
          </div>
        </div>
        <!-- Sales - Region wise
        <div class="col-12 col-lg-6 mt-5">
          <div class="card pb-5 pb-lg-0 h-100">
            <div class="dropdown-wrapper d-none">
              <w-white-dropdown
                :options="regionDropdown"
                :placeHolder="'Select'"
                :selectedOption="selectedRegion"
                :labelText="''"
                @input="selectRegion($event)"
                width="206px"
              ></w-white-dropdown>
            </div>
            <div class="p-4">
              <ColumnChart :chartData="regionWiseData" />
            </div>
          </div>
        </div>  -->

        <!--
        <div class="col-12 col-lg-12 mt-5">
          <div class="card pb-5 pb-lg-0 h-100">
            <div class="p-4">
              <LineChart :chartData="momsSpendsChartData" />
            </div>
          </div>
        </div> -->
        <!-- Spend MOM 
        <div class="col-12 col-lg-12 mt-5">
          <div class="card pb-5 pb-lg-0 h-100">
            <div class="p-4">
              <LineChart :chartData="spendsChartData" />
            </div>
          </div>
        </div>-->
        <!-- Sales - Channel wise 
        <div class="col-12 col-lg-12 mt-5">
          <div class="card pb-5 pb-lg-0 h-100">
            <div class="p-4">
              <ColumnChart :chartData="digitalBreakData" />
            </div>
          </div>
        </div>-->

        <div class="col-12 col-lg-12 mt-5">
          <div class="card pb-5 pb-lg-0 h-100">
            <div class="p-4">
              <ColumnChart :chartData="channelWiseData" />
            </div>
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import StripChart from "@/components/Chart/StripChart.vue";
import BarChart from "@/components/Chart/BarChart.vue";
import ColumnChart from "@/components/Chart/ColumnChart.vue";
import LineChart from "@/components/Chart/LineChart.vue";
// import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
import { AimoSolutionsServices } from "@/services/AimoSolutionsServices.js";
// import PieChart from "@/components/Chart/PieChart.vue";
// import MultipleDonutChart from "@/components/Chart/MultipleDonutChart.vue";
import SingleDropdownCheckbox from "@/widgets/SingleDropdownCheckbox.vue";
import { abbreviateNumber } from "js-abbreviation-number";

const aimoSolutionsServices = new AimoSolutionsServices();
export default {
  components: {
    // "w-white-dropdown": WhiteDropdown,
    // StripChart,
    BarChart,
    ColumnChart,
    LineChart,
    // PieChart,
    // MultipleDonutChart,
    "w-single-dropdown": SingleDropdownCheckbox,
  },
  data() {
    return {
      selectedChannelWiseCost: { name: "Cost", value: "Cost" },
      breakDown: false,

      multipleTitle: ["Media", "Non Media"],

      externalData: [
        {
          name: "Seasonality",
          y: 55,
          z: 92.9,
          size: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 5 ? this.point.name : null;
            },
            color: "#ffffff",
            distance: -30,
          },
          id: "id1",
        },
        {
          name: "Competition",
          y: 23,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id2",
        },
        {
          name: "GDP",
          y: 12,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id3",
        },
        {
          name: "Dollar value",
          y: 10,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              // display only if larger than 1
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id4",
        },
      ],
      internalData: [
        {
          name: "Seasonality",
          y: 55,
          z: 92.9,
          size: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 5 ? this.point.name : null;
            },
            color: "#ffffff",
            distance: -30,
          },
          id: "id1",
        },
        {
          name: "Competition",
          y: 23,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id2",
        },
        {
          name: "GDP",
          y: 12,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id3",
        },
        {
          name: "Dollar value",
          y: 10,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id4",
        },
      ],
      overAllPieColor: ["#64ffda", "#ff4081"],

      overallSeries: [
        {
          name: "Overall",
          colorByPoint: true,
          data: [
            {
              name: "Media",
              y: 23,
              sliced: true,
              selected: true,
            },
            {
              name: "Non Media",
              y: 77,
            },
          ],
        },
      ],
      regionDropdown: [
        { text: "City wise", id: "city wise" },
        { text: "State wise", id: "state wise" },
        { text: "Country wise", id: "country wise" },
      ],
       adtypeList: [
        { name: "Impressions", value: "Impressions" },
        { name: "Clicks", value: "Clicks" },
        { name: "Cost", value: "Cost" },
        { name: "Conversions", value: "Conversions" },
      ],
      mediaMixData: [
        {
          label: "Television",
          value: 49.2,
          backgroundColor: "#67e8ff",
        },
        {
          label: "Digital",
          value: 19,
          backgroundColor: "#b5a9ff",
        },
        {
          label: "Sponsorship",
          value: 12,
          backgroundColor: "#faacfe",
        },
        {
          label: "OOH",
          value: 11,
          backgroundColor: "#81f89e",
        },
        {
          label: "Print",
          value: 5.3,
          backgroundColor: "#ffa2a2",
        },
        {
          label: "Radio",
          value: 3.5,
          backgroundColor: "#ffdd7c",
        },
      ],
      socialBarData: {
        height: 400,
        // scrollableHeight: 500,
        colors: [
          "#ffdd7c",
          "#b5a9ff",
          "#ffa2a2",
          "#fdbfff",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4",
        ],
        title: {
          text: "Sales - Year wise(Cr)",
          align: "left",
          margin: 20,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
          scrollbar: {
            enabled: true,
            barBackgroundColor: "#F7FAFF",
            barBorderRadius: 7,
            barBorderWidth: 0,
            buttonBackgroundColor: "white",
            buttonBorderWidth: 0,
            buttonArrowColor: "white",
            buttonBorderRadius: 0,
            rifleColor: "#F7FAFF",
            trackBackgroundColor: "white",
            trackBorderWidth: 0,
            trackBorderColor: "#FAFAFA",
            trackBorderRadius: 0,
            width: 50,
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
            labels: {
              style: {
                color: "#a9b6cd",
                fontSize: "12px",
                fontFamily: "ProximaNovaRegular",
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label;
              },
            },
            gridLineDashStyle: "shortdash",
          },
        ],
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
            pointPadding: 0.3,
            colorByPoint: true,
          },
          showInLegend: true,
          series: {
             dataLabels: {
              enabled: true,
              formatter: function() {
                return abbreviateNumber(this.y, 1, {
                  symbols: ["", "k", "M", "B", "T", "P", "E"],
                });
              },
            },
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Sales - Year wise",
            data: [],
          },
        ],
      },
      newStoreData: {
        //scrollableWidth: 1200,
        data: [
          {
            name: "Number of new Stores",
            data: [],
          },
        ],
        title: {
          text: "Number of new Stores",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [],
          crosshair: {
            enabled: true,
          },
          scrollbar: {
            enabled: true,
            barBackgroundColor: "#F7FAFF",
            barBorderRadius: 7,
            barBorderWidth: 0,
            buttonBackgroundColor: "white",
            buttonBorderWidth: 0,
            buttonArrowColor: "white",
            buttonBorderRadius: 0,
            rifleColor: "#F7FAFF",
            trackBackgroundColor: "white",
            trackBorderWidth: 0,
            trackBorderColor: "#FAFAFA",
            trackBorderRadius: 0,
            width: 50,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.4,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      regionWiseData: {
        //scrollableWidth: 1200,
        data: [
          {
            name: "Sales - Region wise",
            data: [],
          },
        ],
        title: {
          text: "Sales - Region wise",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [],
          crosshair: {
            enabled: true,
          },
          scrollbar: {
            enabled: true,
            barBackgroundColor: "#F7FAFF",
            barBorderRadius: 7,
            barBorderWidth: 0,
            buttonBackgroundColor: "white",
            buttonBorderWidth: 0,
            buttonArrowColor: "white",
            buttonBorderRadius: 0,
            rifleColor: "#F7FAFF",
            trackBackgroundColor: "white",
            trackBorderWidth: 0,
            trackBorderColor: "#FAFAFA",
            trackBorderRadius: 0,
            width: 50,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.4,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      stackChartData: {
        //scrollableWidth: 1200,
        data: [],
        title: {
          text: "Yearly Spends by Medium(Cr)",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: true,
        },
        xAxis: {
          categories: [],
          crosshair: {
            enabled: true,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: true,
            },
          },
          series: {
            borderRadiusTopLeft: 0,
            borderRadiusTopRight: 0,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      momsSpendsChartData: {
        series: [
          {
            data: [],
            marker: {
              symbol: "circle",
              radius: 10,
              status: {
                hover: {
                  enabled: true,
                },
              },
            },
            type: "spline",
            name: "TV",
          },
          {
            data: [],
            marker: {
              symbol: "circle",
              radius: 10,
              status: {
                hover: {
                  enabled: true,
                },
              },
            },
            type: "spline",
            name: "Print",
          },
          {
            data: [],
            marker: {
              symbol: "circle",
              radius: 10,
              status: {
                hover: {
                  enabled: true,
                },
              },
            },
            type: "spline",
            name: "Radio",
          },
          {
            data: [],
            marker: {
              symbol: "circle",
              radius: 10,
              status: {
                hover: {
                  enabled: true,
                },
              },
            },
            type: "spline",
            name: "Digital",
          },
          {
            data: [],
            marker: {
              symbol: "circle",
              radius: 10,
              status: {
                hover: {
                  enabled: true,
                },
              },
            },
            type: "spline",
            name: "OOH",
          },
        ],
        title: {
          text: "Quartly spends by channel",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        legend: {
          align: "left",
          verticalAlign: "bottom",
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 40,
          itemMarginTop: 30,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        yAxis: {
          title: {
            text: "",
            style: {
              color: "#8394b4",
            },
          },
          gridLineDashStyle: "shortdash",
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "12px",
              fontFamily: "ProximaNovaRegular",
            },
            formatter: function () {
              var label = this.axis.defaultLabelFormatter.call(this);
              return label;
            },
          },
        },
        xAxis: {
          categories: [],
          title: {
            text: "",
            style: {
              color: "#8394b4",
            },
          },
          type: "line",
          labels: {
            format: "{value:Q%q-%Y}",
            style: {
              color: "#9aafd4",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        colors: ["#050505", "#faacfe", "#b5a9ff", "#67e8ff"],
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
          formatter: function () {
            const x = this.x;
            var value = this.series.data.find((obj) => obj.category === x)
              .options.y;
            if (this.series.name) {
              return ` ${this.series.name}
              : <b>
            ${value}
              </b>`;
            } else {
              return value;
            }
          },
        },
      },
      spendsChartData: {
        series: [
          {
            data: [],
            marker: {
              symbol: "circle",
              radius: 10,
              status: {
                hover: {
                  enabled: true,
                },
              },
            },
            type: "spline",
            name: "Swiggy",
          },
          {
            data: [],
            marker: {
              symbol: "circle",
              radius: 10,
            },
            type: "spline",
            name: "Zomato",
          },
        ],
        title: {
          text: "Spends on aggregators",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        legend: {
          align: "left",
          verticalAlign: "bottom",
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 40,
          itemMarginTop: 30,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        yAxis: {
          title: {
            text: "",
            style: {
              color: "#8394b4",
            },
          },
          gridLineDashStyle: "shortdash",
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "12px",
              fontFamily: "ProximaNovaRegular",
            },
            formatter: function () {
              var label = this.axis.defaultLabelFormatter.call(this);
              return label;
            },
          },
        },
        xAxis: {
          categories: [
            "Q1-2017",
            "Q2-2017",
            "Q3-2017",
            "Q4-2017",
            "Q1-2018",
            "Q2-2018",
            "Q3-2018",
            "Q4-2018",
            "Q1-2019",
            "Q2-2019",
            "Q2-2019",
            "Q2-2019",
          ],
          title: {
            text: "",
            style: {
              color: "#8394b4",
            },
          },
          type: "line",
          labels: {
            format: "{value:Q%q-%Y}",
            style: {
              color: "#9aafd4",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        colors: ["#050505", "#faacfe", "#b5a9ff", "#67e8ff"],
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
          formatter: function () {
            const x = this.x;
            var value = this.series.data.find((obj) => obj.category === x)
              .options.y;
            if (this.series.name) {
              return ` ${this.series.name}
              : <b>
            ${value}
              </b>`;
            } else {
              return value;
            }
          },
        },
      },
      lineChartData: {
        series: [
          {
            data: [
              64.63, 18.23, 23.12, 20.66, 30.89, 96.8, 22.36, 30.89, 12.85,
              36.58, 13.17, 15.73,
            ],
            marker: {
              symbol: "circle",
              radius: 10,
              status: {
                hover: {
                  enabled: true,
                },
              },
            },
            type: "spline",
            name: "Category 1",
          },
          {
            data: [
              30.53, 28.55, 31.82, 32.84, 38.72, 38.01, 42.37, 38.72, 40.74,
              73.72, 48.95, 41.56,
            ],
            marker: {
              symbol: "circle",
              radius: 10,
            },
            type: "spline",
            name: "Category 2",
          },
          {
            data: [
              10.86, 12.35, 11.81, 10.16, 12.48, 12.32, 12.68, 12.48, 17.97,
              15.91, 14.45, 14.27,
            ],
            marker: {
              symbol: "circle",
              radius: 10,
            },
            type: "spline",
            name: "Category 3",
          },
          {
            data: [
              64.63, 48.36, 64.42, 55.31, 71.28, 57.02, 73.64, 71.28, 80.42,
              76.24, 85.43, 74.25,
            ],
            marker: {
              symbol: "circle",
              radius: 10,
            },
            type: "spline",
            name: "Category 4",
          },
        ],
        title: {
          text: "Sales - Product wise",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        legend: {
          align: "left",
          verticalAlign: "bottom",
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 40,
          itemMarginTop: 30,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        yAxis: {
          title: {
            text: "",
            style: {
              color: "#8394b4",
            },
          },
          gridLineDashStyle: "shortdash",
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "12px",
              fontFamily: "ProximaNovaRegular",
            },
            formatter: function () {
              var label = this.axis.defaultLabelFormatter.call(this);
              return label;
            },
          },
        },
        xAxis: {
          categories: [
            "Q1-2017",
            "Q2-2017",
            "Q3-2017",
            "Q4-2017",
            "Q1-2018",
            "Q2-2018",
            "Q3-2018",
            "Q4-2018",
            "Q1-2019",
            "Q2-2019",
            "Q2-2019",
            "Q2-2019",
          ],
          title: {
            text: "",
            style: {
              color: "#8394b4",
            },
          },
          type: "line",
          labels: {
            format: "{value:Q%q-%Y}",
            style: {
              color: "#9aafd4",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        colors: ["#050505", "#faacfe", "#b5a9ff", "#67e8ff"],
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
          formatter: function () {
            const x = this.x;
            var value = this.series.data.find((obj) => obj.category === x)
              .options.y;
            if (this.series.name) {
              return ` ${this.series.name}
              : <b>
            ${value}
              </b>`;
            } else {
              return value;
            }
          },
        },
      },
      digitalBreakData: {
        data: [
          {
            name: "E-commerce",
            data: [102170000, 117220000, 135360000],
          },
          {
            name: "Grocery Retail",
            data: [57910000, 62330000, 68280000],
          },
        ],
        title: {
          text: "",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#faacfe", "#67e8ff", "#ffdd7c", "#b5a9ff", "#ffa2a2"],
        legend: {
          align: "left",
          verticalAlign: "bottom",
          layout: "horizontal",
          itemMarginTop: 30,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
            fontFamily: "ProximaNovaRegular",
          },
        },
        xAxis: {
          categories: ["2017", "2018", "2019"],
          crosshair: {
            enabled: true,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.3,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },

      channelCostChartData: {
        colors: [
          "#050505",
          "#85edff",
          "#b5a9ff",
          "#DDDF00",
          "#24CBE5",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4",
        ],
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
          formatter: function () {
            const x = this.x;
            var value = this.series.data.find((obj) => obj.category === x)
              .options.y;
            if (this.series.name) {
              return ` ${this.series.name}
              : <b>
            ${value}
              </b>`;
            } else {
              return value;
            }
          },
        },
        series: [
          {
            name: "Google Ads",
            data: [107, 31, 635, 203, 2, 133, 156, 947, 408, 6, 107, 31],
          },
          {
            name: "Facebook",
            data: [133, 156, 947, 408, 6, 814, 841, 3714, 727, 31, 133, 156],
          },
          {
            name: "Microsoft Ads",
            data: [
              814, 841, 3714, 727, 31, 1216, 1001, 4436, 738, 40, 814, 841,
            ],
          },
          {
            name: "Kloviyo",
            data: [
              1216, 1001, 4436, 738, 40, 1216, 1001, 4436, 738, 40, 1216, 1001,
            ],
          },
          {
            name: "Online Discounts",
            data: [1216, 1001, 4436, 738, 40, 107, 31, 635, 203, 2, 133, 156],
          },
        ],
        title: {
          text: "",
          align: "right",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {},
        legend: {
          align: "center",
          verticalAlign: "bottom",
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 40,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        yAxis: {
          title: {
            text: "Cost",
            style: {
              color: "#8394b4",
            },
            y: 12,
          },
        },
        xAxis: {
          categories: [
            "JAN-2021",
            "FEB-2021",
            "MAR-2021",
            "APR-2021",
            "MAY-2021",
            "JUN-2021",
            "JULY-2021",
            "AUG-2021",
            "SEP-2021",
            "OCT-2021",
            "NOV-2021",
            "DEC-2021",
          ],
        },
      },
      channelWiseData: {
        data: [
          {
            name: "E-commerce",
            data: [102170000, 117220000, 135360000],
          },
          {
            name: "Grocery Retail",
            data: [57910000, 62330000, 68280000],
          },
          {
            name: "Mordern Trade",
            data: [197230000, 204260000, 215570000],
          },
          {
            name: "Rural",
            data: [51900000, 55330000, 60010000],
          },
          {
            name: "Small Stores",
            data: [78470000, 82060000, 89160000],
          },
        ],
        title: {
          text: "Sales - Channel wise(Cr) ",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#faacfe", "#67e8ff", "#ffdd7c", "#b5a9ff", "#ffa2a2"],
        legend: {
          align: "left",
          verticalAlign: "bottom",
          layout: "horizontal",
          itemMarginTop: 30,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
            fontFamily: "ProximaNovaRegular",
          },
        },
        xAxis: {
          categories: ["2017", "2018", "2019"],
          crosshair: {
            enabled: true,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
            },
          },
          series: {
             dataLabels: {
              enabled: true,
              formatter: function() {
                return abbreviateNumber(this.y, 1, {
                  symbols: ["", "k", "M", "B", "T", "P", "E"],
                });
              },
            },
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.3,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      selectedRegion: {},
    };
  },
  methods: {
    addTrendGraphs() {
      this.channelCostChartData.series = [];
      this.channelCostChartData.series.push(
        {
          name: "Google Ads",
          data: [
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
          ],
        },
        {
          name: "Facebook",
          data: [
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
          ],
        },
        {
          name: "Microsoft Ads",
          data: [
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
          ],
        },
        {
          name: "Kloviyo",
          data: [
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
          ],
        },
        {
          name: "Online Discounts",
          data: [
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
            Math.floor(Math.random() * 10),
          ],
        }
      );
    },
    getSelectedChannelWiseCost(){
      this.addTrendGraphs()

    },
    getBreakdown(newVal) {
      this.breakDown = newVal;
      console.log(this.breakDown, "this.breakDownthis.breakDownthis.breakDown");
    },
    selectRegion(e) {
      this.selectedRegion = e;
    },
    getSalesYearWisehData() {
      aimoSolutionsServices
        .getChartData("ids", "/b2c/in/", "salesyearwise", "no")
        .then((res) => {
          this.socialBarData.series[0].data = [];
          this.socialBarData.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.socialBarData.series[0].data.push(parseInt(res[i].value));
            this.socialBarData.xAxis.categories.push(res[i].name);
          }
        });
    },
    getNumberOfNewStore() {
      aimoSolutionsServices
        .getChartData("ids", "/b2c/in/", "numberofnewstore", "no")
        .then((res) => {
          this.newStoreData.data[0].data = [];
          this.newStoreData.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.newStoreData.data[0].data.push(parseInt(res[i].value));
            this.newStoreData.xAxis.categories.push(res[i].name);
          }
        });
    },
    getSalesRegionWise() {
      aimoSolutionsServices
        .getChartData("ids", "/b2c/in/", "salesregionwise", "no")
        .then((res) => {
          this.regionWiseData.data[0].data = [];
          this.regionWiseData.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.regionWiseData.data[0].data.push(parseInt(res[i].value));
            this.regionWiseData.xAxis.categories.push(res[i].name);
          }
        });
    },
    getYearlySpendByMedium() {
      aimoSolutionsServices
        .getChartData("ids", "/b2c/in/", "yearlyspendbymedium", "no")
        .then((res) => {
          this.stackChartData.data = [];
          this.stackChartData.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.stackChartData.xAxis.categories.push(res[i].name);
            for (var j = 0; j < res[i].Value.length; j++) {
              var dataFind = this.stackChartData.data.find(
                (x) => x.name === res[i].Value[j].name
              );
              if (dataFind !== undefined) {
                var value = Math.round(res[i].Value[j].value * 100) / 100;
                dataFind.data.push(value);
              } else {
                value = Math.round(res[i].Value[j].value * 100) / 100;
                this.stackChartData.data.push({
                  name: res[i].Value[j].name,
                  data: [value],
                });
              }
            }
          }
        });
    },
    getSpendMOM() {
      aimoSolutionsServices
        .getChartData("ids", "/b2c/in/", "spendsmom", "no")
        .then((res) => {
          this.momsSpendsChartData.series[0].data = [];
          this.momsSpendsChartData.series[1].data = [];
          this.momsSpendsChartData.xAxis.categories = [];
          this.momsSpendsChartData.series[2].data = [];
          this.momsSpendsChartData.series[3].data = [];
          this.momsSpendsChartData.series[4].data = [];
          for (var i = 0; i < res.length; i++) {
            this.momsSpendsChartData.series[0].data.push(res[i]["TV"]);
            this.momsSpendsChartData.series[1].data.push(res[i]["Print"]);
            this.momsSpendsChartData.series[2].data.push(res[i]["Radio"]);
            this.momsSpendsChartData.series[3].data.push(res[i]["Digital"]);
            this.momsSpendsChartData.series[4].data.push(res[i]["OOH"]);
            this.momsSpendsChartData.xAxis.categories.push(res[i].name);
          }
        });
    },
    getSpendAggregators() {
      aimoSolutionsServices
        .getChartData("ids", "/b2c/in/", "spendsonaggegerators", "no")
        .then((res) => {
          this.spendsChartData.series[0].data = [];
          this.spendsChartData.series[1].data = [];
          this.spendsChartData.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.spendsChartData.series[0].data.push(res[i]["Swiggy"]);
            this.spendsChartData.series[1].data.push(res[i]["Zomato"]);
            this.spendsChartData.xAxis.categories.push(res[i].name);
          }
        });
    },
    getDigitalSpendByType() {
      aimoSolutionsServices
        .getChartData("ids", "/b2c/in/", "digitalspend", "no")
        .then((res) => {
          this.digitalBreakData.data = [];
          this.digitalBreakData.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.digitalBreakData.xAxis.categories.push(res[i].name);
            for (var j = 0; j < res[i].value.length; j++) {
              var dataFind = this.digitalBreakData.data.find(
                (x) => x.name === res[i].value[j].name
              );
              if (dataFind !== undefined) {
                dataFind.data.push(parseInt(res[i].value[j].value));
              } else {
                this.digitalBreakData.data.push({
                  name: res[i].value[j].name,
                  data: [parseInt(res[i].value[j].value)],
                });
              }
            }
          }
        });
    },
    getSalesChannelWise() {
      aimoSolutionsServices
        .getChartData("ids", "/b2c/in/", "saleschannelwise", "no")
        .then((res) => {
          this.channelWiseData.data = [];
          this.channelWiseData.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.channelWiseData.xAxis.categories.push(res[i].name);
            for (var j = 0; j < res[i].Value.length; j++) {
              var dataFind = this.channelWiseData.data.find(
                (x) => x.name === res[i].Value[j].name
              );
              if (dataFind !== undefined) {
                dataFind.data.push(parseInt(res[i].Value[j].value));
              } else {
                this.channelWiseData.data.push({
                  name: res[i].Value[j].name,
                  data: [parseInt(res[i].Value[j].value)],
                });
              }
            }
          }
        });
    },
  },
  created() {
    this.getSalesYearWisehData();
    this.getNumberOfNewStore();
    this.getSalesRegionWise();
    this.getYearlySpendByMedium();
    this.getSpendMOM();
    this.getSpendAggregators();
    this.getDigitalSpendByType();
    this.getSalesChannelWise();
  },
};
</script>

<style scoped>
.dropdown-wrapper {
  position: absolute;
  right: 10px;
  top: 12px;
  z-index: 9;
}

.breakdownable-card {
  border-left: 2px solid #d4dae4;
  position: relative;
}

.breakdownable-card::before {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  background: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: -17px;
  z-index: 9999;
  clip-path: polygon(50% 0, 0 50%, 50% 100%);
}
.breakdownable-card::after {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  background: #d4dae4;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
  z-index: 999;
  clip-path: polygon(50% 0, 0 50%, 50% 100%);
}
.card-heading {
  margin-left: 20px;
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}
</style>
